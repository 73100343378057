import { FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  HeadquartersResponse,
  OrderResponse,
  OrderTypeListItemRepsonse,
  TenantConfigDto,
  VatTariffResponse,
} from 'src/api';
import { CheckboxRhfc } from 'src/components/fields/Checkbox';
import { SelectRhfc } from 'src/components/fields/Select';
import { TextFieldRhfc } from 'src/components/fields/Textfield/TextField';
import FormDataRow from 'src/components/FormDataRow';
import Toggle from 'src/components/Toggle';
import ToggleB2Cb2B from 'src/components/ToggleB2Cb2B';
import { EMAIL_TARGET_OPTIONS } from 'src/constants/emailTargets';
import { BRANCH_FIELDS, COMPANY_FIELDS, EMPLOYEE_FIELDS } from '../constants';
import { OrderSchema } from '../schema';
import ServiceOrderDurationForm from './ServiceOrderDurationForm';
import getFullTariffName from 'src/helpers/getFullTariffName';

export type ServiceOrderFormProps = {
  headquarters: HeadquartersResponse[];
  orderTypes: OrderTypeListItemRepsonse[];
  vatTariffs: VatTariffResponse[];
  config?: TenantConfigDto;
  onDateChange?: (date: Date) => void;
};

const ServiceOrderForm: FC<ServiceOrderFormProps> = ({
  headquarters,
  orderTypes,
  vatTariffs,
  config,
  onDateChange,
}) => {
  const intl = useIntl();
  const { control, setValue, resetField } = useFormContext<OrderSchema>();

  const useManualOrderNumber = useWatch({ control, name: 'number.useManualOrderNumber' });
  const category = useWatch({ control, name: 'category' });

  const getIsHiddenField = (fieldName: string): boolean =>
    config?.orderForm?.hiddenFields?.includes(fieldName) ?? false;

  const resetContact = (): void => {
    setValue('company.companyId', null as unknown as number);
    COMPANY_FIELDS.forEach((field) => resetField(`company.company.${field}`));

    setValue('branch.branchId', null as unknown as number);
    BRANCH_FIELDS.forEach((field) => resetField(`branch.branch.${field}`));

    setValue('employee.employeeId', null as unknown as number);
    EMPLOYEE_FIELDS.forEach((field) => resetField(`employee.employee.${field}`));

    resetField('orderProducts');
  };

  const clearProducts = (): void => {
    setValue('orderProducts', []);
  };

  return (
    <div className='flex flex-col mb-16'>
      <div className='flex space-x-44 mb-2 mt-4'>
        <div className='font-bold text-gray-800 text-2xl mb-8'>
          <FormattedMessage id='app.order.section_title.order' />
        </div>
      </div>
      {!getIsHiddenField('useManualOrderNumber') && (
        <FormDataRow label={<FormattedMessage id='app.order.use_manual_order_number' />}>
          <Controller
            control={control}
            name='number.useManualOrderNumber'
            render={({ field }) => <Toggle value={field.value} onChange={field.onChange} />}
          />
        </FormDataRow>
      )}
      {!getIsHiddenField('clientOrderNumber') && config?.enableClientOrderNumber && (
        <FormDataRow label={<FormattedMessage id='app.order.client_number' />}>
          <TextFieldRhfc control={control} name='clientOrderNumber' />
        </FormDataRow>
      )}
      {!getIsHiddenField('category') && config?.allowB2C && (
        <FormDataRow label={<FormattedMessage id='app.customer.category' />}>
          <Controller
            control={control}
            name='category'
            render={({ field }) => (
              <ToggleB2Cb2B
                value={field.value}
                onChange={(v) => {
                  field.onChange(v);
                  setValue('company.company.category', v);
                  resetContact();
                  clearProducts();
                }}
              />
            )}
          />
        </FormDataRow>
      )}

      {useManualOrderNumber && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.manual_order_number' />}>
          <TextFieldRhfc name='number.manualOrderNumber' control={control} />
        </FormDataRow>
      )}
      {!getIsHiddenField('headquartersId') && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.headquarters' />}>
          <SelectRhfc
            control={control}
            name='headquartersId'
            options={headquarters ?? []}
            extraOnChange={(v) => {
              const hq = headquarters?.find((i) => i.id === v);
              if (!hq) return;

              setValue('vatTariffId', hq.defaultVatTariffId);
            }}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => `${i.companyName} ${i.companyStreet} ${i.companyCity}`}
          />
        </FormDataRow>
      )}

      {!getIsHiddenField('orderTypeId') && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.order_type' />}>
          <SelectRhfc
            control={control}
            name='orderTypeId'
            options={orderTypes ?? []}
            getOptionValue={(i) => i.id}
            getOptionLabel={(i) => i.name}
          />
        </FormDataRow>
      )}

      <ServiceOrderDurationForm onDateChange={onDateChange} />

      {!getIsHiddenField('vatTariffId') && !config?.disableVat && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.vat_tariff' />}>
          <SelectRhfc
            control={control}
            name='vatTariffId'
            options={vatTariffs ?? []}
            getOptionValue={(i) => i.id}
            getOptionLabel={getFullTariffName}
          />
        </FormDataRow>
      )}

      {!getIsHiddenField('emailTarget') && category !== OrderResponse.category.B2C && (
        <FormDataRow asterisk label={<FormattedMessage id='app.order.email_target' />}>
          <SelectRhfc
            control={control}
            name='emailTarget'
            options={EMAIL_TARGET_OPTIONS}
            getOptionValue={(i) => i.value}
            getOptionLabel={(i) => intl.formatMessage(i.labelRs)}
          />
        </FormDataRow>
      )}

      {!getIsHiddenField('invoiceNumber') && (
        <FormDataRow label={<FormattedMessage id='app.order.invoice_number' />}>
          <TextFieldRhfc control={control} name='invoiceNumber' />
        </FormDataRow>
      )}

      <FormDataRow label={<FormattedMessage id='app.order.should_send_notifications' />}>
        <CheckboxRhfc control={control} name='shouldSendNotifications' />
      </FormDataRow>
    </div>
  );
};

export default ServiceOrderForm;
