import { useCallback } from 'react';
import { useMemo } from 'react';
import { FC } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { OrderResponse, SavedOrderFormResponse } from 'src/api';
import useFinishTechnicianOrder from 'src/api/hooks/mutations/technicianOrder/useFinishTechnicianOrder';
import Button from 'src/components/Button';
import Popup from 'src/components/Popup';
import WithLoader from 'src/components/utils/WithLoader';
import useUnits from 'src/hooks/useUnits';
import { noop } from 'src/utils/commonUtils';
import { createTechnicianOrderFormDefaultValues } from '../../helpers';
import FinishOrderForm from '../FinishOrderForm/FinishOrderForm';
import { FinishOrderFormValues, OrderFinishSchema, OrderFinishWithRealTimeSchema } from '../FinishOrderForm/schema';
import { sanitizeForm } from '../FinishOrderForm/helpers';
import { zodResolver } from '@hookform/resolvers/zod';

export type FinishOrderFormContainerProps = {
  className?: string;
  order: OrderResponse;
  savedForm?: SavedOrderFormResponse;
};

const FinishOrderFormContainer: FC<FinishOrderFormContainerProps> = ({ order, savedForm, className }) => {
  const intl = useIntl();
  const finishOrder = useFinishTechnicianOrder();
  const defaultValues = useMemo(() => {
    return savedForm
      ? sanitizeForm(savedForm.json as OrderFinishSchema)
      : createTechnicianOrderFormDefaultValues(order, intl);
  }, []);

  const schema = order?.orderType?.slConfig?.allowManualRealTime ? OrderFinishWithRealTimeSchema : OrderFinishSchema;
  const form = useForm<OrderFinishSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues as unknown as OrderFinishSchema,
    reValidateMode: 'onChange',
  });

  const units = useUnits();

  const handleSubmit: SubmitHandler<OrderFinishSchema> = useCallback(
    async (data) => {
      const modifiedData = {
        ...data,
        ...data.customerSignature,
        slConfigOutput: (data.slConfigOutput && JSON.stringify(data.slConfigOutput)) || null,
        products: data.products.map((product) => ({
          ...product,
          materials:
            product.materials?.map((m) => ({
              ...m,
              unit: units.find((u) => u.value === m.unit)?.label ?? '',
            })) ?? [],
        })),
        serviceDuration: Number(data.serviceDuration),
        serviceMileage: Number(data.serviceMileage),
        serviceTotalPrice: order?.orderType?.slConfig?.allowManualPrices ? null : data.serviceTotalPrice,
      };

      finishOrder.mutate(modifiedData as unknown as FinishOrderFormValues);
    },
    [finishOrder, order],
  );

  return (
    <WithLoader isLoading={finishOrder.isLoading}>
      <FormProvider {...form}>
        <FinishOrderForm order={order} onSubmit={handleSubmit} className={className} />
      </FormProvider>

      <Popup
        open={finishOrder.isSuccess}
        onClose={noop}
        centerTitle
        hideCloseIcon
        title={<FormattedMessage id='app.finish_order.finished' />}
      >
        <Button
          className='w-full'
          onClick={() => {
            window.opener = null;
            window.open('', '_self');
            window.close();
          }}
        >
          <FormattedMessage id='app.finish_order.close_page' />
        </Button>
      </Popup>
    </WithLoader>
  );
};

FinishOrderFormContainer.displayName = 'FinishOrderFormContainer';

export default FinishOrderFormContainer;
